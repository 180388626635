import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["bar", "placeholder", "barGraph"]
  static values = {
    overdueCount: Number,
    tasksAssignedToday: Number,
    toDoTasks: Number,
    completedTasksThisWeek: Number
  }

  connect() {
    const barData = [
      { value: this.overdueCountValue, target: 'overdue' },
      { value: this.tasksAssignedTodayValue, target: 'tasksAssignedToday' },
      { value: this.toDoTasksValue, target: 'toDo' },
      { value: this.completedTasksThisWeekValue, target: 'completedThisWeek' }
    ];
    // Add all values together so division can be used to calculate percentage
    const barValues = this.overdueCountValue + this.tasksAssignedTodayValue + this.toDoTasksValue + this.completedTasksThisWeekValue;
    
    // Don't display bar, remove from DOM, if value is less than 1
    barData.forEach(({ value, target }) => {
      if (value < 1) {
        this.barTargets.find(el => el.dataset.barType === target)?.remove();
      }
    });

    // Set the width of each bar based on the value percentage
    this.barTargets.forEach((bar) => {
      const value = this.barTargets.find(el => el.dataset.barType === bar.dataset.barType)?.dataset.value;
      const percentage = (value / barValues) * 100;
      bar.style.width = `${percentage}%`;
    });

  }
}