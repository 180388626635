import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  selectAll(e) {
    const formId = e.target.dataset.formId;
    const submitButton = document.querySelector('#form-submit-btn');
    const checkboxes = document.querySelectorAll(`#${formId} input[type="checkbox"]`);
    const isChecked = e.currentTarget.checked;
  
    checkboxes.forEach(checkbox => checkbox.checked = isChecked);
    
    submitButton.classList.toggle('disabled', !isChecked);
  }

  enableCreate(e) {
    const formId = e.target.dataset.formId;
    const submitButton = document.querySelector('#form-submit-btn');
    const checkboxes = document.querySelectorAll(`#${formId} input[type="checkbox"]`);

    const isAtLeastOneChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
    submitButton.classList.toggle('disabled', !isAtLeastOneChecked);
  }
}
