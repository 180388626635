import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  submitFilters(e) {
    const selectedValue = document.querySelector('#archived_date_range').value;
    const moduleType = e.target.dataset.moduleType;
    
    let filters;
    if ( selectedValue == 'In Between' ) {
      filters = {
        selectedValue: selectedValue,
        startDate: document.querySelector('#archived-date-from').value,
        endDate: document.querySelector('#archived-date-to').value
      };
    } else if ( selectedValue == 'All' ) {
      this.resetFilters(e);
    } else {
      filters = {
        selectedValue: selectedValue,
        startDate: document.querySelector('#archived-date--single').value
      }
    }
    const queryString = new URLSearchParams(filters).toString();

    document.querySelector('#archived-data-content-container').setAttribute('src', `/admins/archived_data?module_type=${moduleType}&filters=${encodeURIComponent(queryString)}`);
  }

  resetFilters(e) {
    document.querySelector('#archived-data-content-container').setAttribute('src', `/admins/archived_data?module_type=${e.target.dataset.moduleType}`);
  }
}