import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ "search"];

  connect() {
    const taskLibrary = document.querySelector('#custom_task_false');
    const taskField = document.querySelector(".task-name");

    if (taskLibrary.checked) this.setControllerAndAction(taskField);
  }

  setDataProps(e) {
    const setProps = e.target.dataset.toSetProps;
    const taskField = document.querySelector(".task-name");
    const searchResult = document.querySelector('#search-results');
    const libraryTaskValue = document.querySelector('#library_task_id');

    if (setProps === "true") return this.setControllerAndAction(taskField);

    taskField.removeAttribute("data-controller");
    taskField.removeAttribute("data-action");
    this.addVanish();
    searchResult.removeAttribute('src');
    libraryTaskValue.value = ''
  }

  populateLibraryTasks(event) {
    const inputValue = event.target.value;
    const moduleType = event.target.dataset.moduleType;
    const searchResultSelector = document.querySelector('#search-results');
    searchResultSelector.classList.remove('vanish');
    searchResultSelector.setAttribute('src', `/fetch_search_result_library_task?module_type=${moduleType}&query=${inputValue}`);
  }

  chooseName(event) {
    event.preventDefault();
    const nameValue = this.searchTarget.getAttribute("data-library-task-name");
    const libTaskId = this.searchTarget.getAttribute("data-library-task-lib-id");
    const taskSelector = document.querySelector('.task-name');
    const libraryTaskSelector = document.querySelector('#library_task_id');
    
    taskSelector.value = nameValue;
    libraryTaskSelector.setAttribute('value', libTaskId);
    this.addVanish();
  }
  
  addVanish() {
    setTimeout(() => {
      const searchResultSelector = document.querySelector('#search-results');
      searchResultSelector.classList.add('vanish');
    }, 200);
  }

  setControllerAndAction(field) {
    field.setAttribute("data-controller", "library-task");
    field.setAttribute("data-action", "input->library-task#populateLibraryTasks blur->library-task#addVanish");
  }
}