import { Controller } from "stimulus";
// import StimulusReflex from "stimulus_reflex";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["objects", "item"];

  connect() {
    super.connect()
    if (!this.hasObjectsTarget) return;
    if (this.objectsTarget.dataset.type == "index-sort") {
      Sortable.create(this.objectsTarget, {
        onEnd: (event) => this.reorder(event),
        draggable: ".draggable-item"
      });
  } else {
      Sortable.create(this.objectsTarget, {
        onEnd: (event) => this.reorder(event),
        delay: 1000, // time in milliseconds to define when the sorting should start
        delayOnTouchOnly: true, // only delay if user is using touch
        swapThreshold: 0.75,
        animation: 150,
        direction: 'vertical',
        handle: ".draggable-item"
      });
    }
  }

  reorder(event) {
    Array.from(event.item.parentElement.querySelectorAll(".select-value-container")).forEach((item, index) => {
        item.querySelector(".hidden-field-for-position").value = index
    });
  }

  comparator(a, b) {
    a = parseInt(a.querySelector(".hidden-field-for-position").value);
    b = parseInt(b.querySelector(".hidden-field-for-position").value);
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
}
