import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  submitFilters(e) {

    const moduleType = e.currentTarget.dataset.moduleType;
    const filterType = e.currentTarget.dataset.filterType;

    const taskTab = document.querySelector('#tab-1');
    taskTab.setAttribute('data-is-active', 'true');
    taskTab.setAttribute('aria-selected', 'true');

    const resourceTab = document.querySelector('#tab-0');
    resourceTab.setAttribute('data-is-active', 'false');
    resourceTab.setAttribute('aria-selected', 'false');

    const srcValue = `/tasks?module_type=${encodeURIComponent(moduleType)}&from_summary=true&filter_type=${filterType}`;
    document.getElementById('resources-index--container--page-bottom-view-frame').setAttribute('src', srcValue);

    // Scroll to the bottom of the page and show toast when the frame is rendered
    const onTurboFrameRender = (ev) => {
      const decodedSrc = this.decodeUrlWithPlus(ev.target.src);
      const decodedSrcValue = this.decodeUrlWithPlus(srcValue);
      if (ev.target.id == "resources-index--container--page-bottom-view-frame" && decodedSrc.includes(decodedSrcValue)) {
        document.getElementById('index-tasks-card-container--list-container').scrollIntoView({ behavior: 'smooth', block: 'end' });
        const label = this.element.querySelector(".summary-component--detail-component--content--label--text").innerText;
        super.renderToast("body", `Filtering by ${label}`, null, false, null, 6000);

        // Remove the event listener after it fires
        document.removeEventListener("turbo:frame-render", onTurboFrameRender);
      }
    };

    document.addEventListener("turbo:frame-render", onTurboFrameRender);
  }

  // New method to decode URL and handle '+' signs
  decodeUrlWithPlus(url) {
    return decodeURIComponent(url.replace(/\+/g, ' '));
  }
}
